import React from 'react'
import HeaderInnerLogo from './HeaderInnerLogo'
import HeaderMenuBtn from './HeaderMenuBtn'
import HeaderMenuDropdown from './HeaderMenuDropdown'

class Header extends React.Component {
  state = {
    menuDropDownActive: false,
  }

  menuDropDownToggle = () => {
    this.setState({ menuDropDownActive: !this.state.menuDropDownActive })
  }

  render() {
    return (
      <>
        <HeaderMenuDropdown
          menuDropDownActive={this.state.menuDropDownActive}
        />
        <header className="header">
          <div className="container-fluid">
            <div className="header__inner">
              <HeaderInnerLogo affiliate={this.props.affiliate} />
              <HeaderMenuBtn
                menuDropDownToggle={this.menuDropDownToggle}
                menuDropDownActive={this.state.menuDropDownActive}
              />
            </div>
          </div>
        </header>
      </>
    )
  }
}

export default Header
