import React from 'react'
import { withRouter } from 'react-router-dom'

class FormFooterConstructorThird extends React.Component {
  render() {
    return (
      <div
        className={
          'build__bottpanel stage-2 stage-3' +
          ' ' +
          (this.props.classAdd !== undefined ? this.props.classAdd : '')
        }
      >
        <div className="build__bottpanel-btnback">
          <a className="btn-1" href="#"></a>
        </div>
        <div className="build__bottpanel-btnfs air">
          <button
            className="btn-1"
            type={'button'}
            onClick={() => {
              this.props.saveOrder()
            }}
          >
            {' '}
            <span>REQUEST A PRICE</span>{' '}
          </button>
          <a
            className="global-btn btn-3 "
            href="#"
            onClick={(e) => {
              e.preventDefault()
              this.props.setSeeMyDoor(true)
            }}
          >
            SEE MY DOOR ON MY HOUSE
          </a>
        </div>
        <div className="build__bottpanel-btnsummary"></div>
      </div>
    )
  }
}

export default withRouter(FormFooterConstructorThird)
