import React from "react";
import { Link } from "react-router-dom";

class FavouriteStyleBannerSecond extends React.Component {
  render() {
    return (
      <div className="favourite-style-banner">
        <div className="favourite-style-banner__title">
          Why everyone loves an Apeer Door
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="7.495"
            height="10.289"
            viewBox="0 0 7.495 10.289"
          >
            <g
              id="Сгруппировать_482"
              data-name="Сгруппировать 482"
              transform="translate(237.169 -431.053) rotate(90)"
            >
              <path
                id="Контур_451"
                data-name="Контур 451"
                d="M441.1,233.66h-9.813a.238.238,0,0,1,0-.476H441.1a.238.238,0,1,1,0,.476Z"
              />
              <path
                id="Контур_452"
                data-name="Контур 452"
                d="M441.1,233.66a.238.238,0,0,1-.168-.07l-3.51-3.509a.238.238,0,0,1,.337-.337l3.509,3.509a.239.239,0,0,1-.168.407Z"
              />
              <path
                id="Контур_453"
                data-name="Контур 453"
                d="M437.6,237.169a.234.234,0,0,1-.168-.07.237.237,0,0,1,0-.336l3.508-3.51a.238.238,0,0,1,.337.337l-3.509,3.509A.234.234,0,0,1,437.6,237.169Z"
              />
            </g>
          </svg>
          <div className="favourite-style-footer-text">
            Our doors are chosen by homeowners who appreciate exceptional
            craftsmanship, timeless elegance and built-to-last quality. They
            quickly discover that all this good stuff means plenty more:
          </div>
          <div className="favourite-style-footer-link">
            <Link to={"/energy-efficiency"}>
              <div className="favourite-style-footer-link-img">
                <img
                  src={
                    require("../../assets/images/energy-efficiency/efficiency-info-2.jpg")
                     
                  }
                  alt=""
                />
              </div>
              <span>Energy Efficiency</span>
            </Link>
            <Link to={"/security-features"}>
              <div className="favourite-style-footer-link-img">
                <img
                  src={
                    require("../../assets/images/energy-efficiency/Security-Features-1.jpg")
                     
                  }
                  alt=""
                />
              </div>
              <span>Security Features</span>
            </Link>
            <Link to={"/colour-choices"}>
              <div className="favourite-style-footer-link-img">
                <img
                  src={
                    require("../../assets/images/energy-efficiency/Colour-Choices-1.jpg")
                     
                  }
                  alt=""
                />
              </div>
              <span>Colour Choices</span>
            </Link>
            <Link to={"/glass-designs"}>
              <div className="favourite-style-footer-link-img">
                <img
                  src={
                    require("../../assets/images/energy-efficiency/Glass-Designs-1.jpg")
                      
                  }
                  alt=""
                />
              </div>
              <span>Glass Designs</span>
            </Link>
          </div>
        </div>

        <div className="favourite-style-banner-img">
          <img
            src={require("../../assets/images/favorite-style-bg.jpg")}
            alt=""
          />
        </div>
      </div>
    );
  }
}

export default FavouriteStyleBannerSecond;
