import React from 'react'
import ModalWarning from './ModalWarning'

function OverlayBuilderValidation(props) {
  return (
    <div className="overlay-builder-validation">
      {props.children}
      {props.status ? (
        <div className="overlay-builder">
          <ModalWarning
            message={props.modalMessage}
            setStatus={props.setStatus}
          />
        </div>
      ) : null}
    </div>
  )
}
export default OverlayBuilderValidation
