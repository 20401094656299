import React from 'react'
import TitleConstructor from '../../ElementsConstructor/TitleConstructor'
import FormFooterConstructorThird from '../../ElementsConstructor/FormFooterConstructorThird'
import BuildItemsConstructor from '../../ElementsConstructor/BuildItemsConstructor'
import MobileTitleConstructorSecond from '../../ElementsConstructor/MobileTitleConstructorSecond'
import SimpleBar from 'simplebar-react'
import { connect } from 'react-redux'
import ReactPixel from 'react-facebook-pixel'
class MyDreamDoor extends React.Component {
  state = {
    errors: {
      post_code: false,
      county: false,
      name: false,
      address_number_1: false,
      address_number_2: false,
      phone_number: false,
      email: false,
      coupon: false,
    },
  }

  validate = () => {
    let errors = {
      post_code: false,
      county: false,
      name: false,
      address_number_1: false,
      address_number_2: false,
      phone_number: false,
      email: false,
      coupon: false,
    }
    let uk =
      /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s[0-9][A-Za-z]{2})$/
    let irish = /(?:^[AC-FHKNPRTV-Y][0-9]{2}|D6W)[ -][0-9AC-FHKNPRTV-Y]{4}$/
    let regexp = ''

    if (!this.props.formData.county) {
      errors.county = true
    }
    if (this.props.formData.county === 'United Kingdom') {
      regexp = uk
    } else if (this.props.formData.county === 'Ireland') {
      regexp = irish
    }
    if (!errors.county) {
      if (!regexp.test(this.props.formData.post_code.trim())) {
        errors.post_code = true
      }
    }
    if (!this.props.formData.name) {
      errors.name = true
    }
    if (!this.props.formData.address_number_1) {
      errors.address_number_1 = true
    }

    if (!this.props.formData.phone_number) {
      errors.phone_number = true
    }
    if (!this.props.formData.email) {
      errors.email = true
    }

    return errors
  }

  validateCheckError = (errors) => {
    let i,
      status = false
    Object.keys(errors).forEach((field, index) => {
      if (errors[field]) {
        status = true
      }
    })
    return status
  }

  render() {
    // console.log()
    return (
      <>
        <TitleConstructor title="My dream door" classAdd="white" />
        <div
          className="panel-title"
          onClick={() => {
            this.props.setSeeMyDoor(true)
          }}
        >
          VIEW ON MY HOUSE +
        </div>
        <MobileTitleConstructorSecond
          classAdd="build__title-mobile--hiden"
          title="Please send me a price"
        />
        <BuildItemsConstructor>
          <SimpleBar className="scrollable-area" forceVisible="y">
            <div className="details-form ">
              <div className="details-form__inner-box">
                <div className="details-form-title-mob">Your dream door!</div>
                <div className="details-form-door-preview">
                  <img src={this.props.image} alt="" />
                </div>
                <div className="please-send-btn-mob">
                  Please send me a price
                </div>
                <div className="details-form__inner dream-door">
                  <form>
                    <div className="details-form__form">
                      <div
                        className={`details-form__form-input  ${
                          this.state.errors.name ? 'error' : ''
                        }`}
                      >
                        <input
                          value={this.props.formData.name}
                          type="text"
                          onChange={this.props.nameOnChangeHandler}
                          placeholder="NAME"
                        />
                      </div>
                      {this.state.errors.name ? (
                        <div
                          className={`details-form__form-input_description error`}
                        >
                          Name is required.
                        </div>
                      ) : null}
                      <div
                        className={`details-form__form-input  ${
                          this.state.errors.address_number_1 ? 'error' : ''
                        }`}
                      >
                        <input
                          type="text"
                          placeholder="1ST LINE ADDRESS"
                          value={this.props.formData.address_number_1}
                          onChange={this.props.addressNumber1OnChangeHandler}
                        />
                      </div>
                      {this.state.errors.address_number_1 ? (
                        <div
                          className={`details-form__form-input_description error`}
                        >
                          1st line address is required.
                        </div>
                      ) : null}
                      <div
                        className={`details-form__form-input  ${
                          this.state.errors.address_number_2 ? 'error' : ''
                        }`}
                      >
                        <input
                          type="text"
                          placeholder="2ND LINE ADDRESS"
                          value={this.props.formData.address_number_2}
                          onChange={this.props.addressNumber2OnChangeHandler}
                        />
                      </div>

                      {this.state.errors.address_number_2 ? (
                        <div
                          className={`details-form__form-input_description error`}
                        >
                          2nd line address is required.
                        </div>
                      ) : null}
                      <div
                        className={`details-form__form-input ${
                          this.state.errors.county ? 'error' : ''
                        }`}
                      >
                        <select
                          type="text"
                          value={this.props.formData.county}
                          onChange={this.props.countyOnChangeHandler}
                        >
                          <option value={''}>Select Country ...</option>
                          <option value={'United Kingdom'}>
                            United Kingdom
                          </option>
                          <option value={'Ireland'}>Ireland</option>
                        </select>
                      </div>
                      {this.state.errors.county ? (
                        <div
                          className={`details-form__form-input_description error`}
                        >
                          County is required.
                        </div>
                      ) : null}
                      <div
                        className={`details-form__form-input ${
                          this.state.errors.post_code ? 'error' : ''
                        }`}
                      >
                        <input
                          type="text"
                          value={this.props.formData.post_code}
                          onChange={this.props.postcodeOnChangeHandler}
                          placeholder="POSTCODE"
                        />
                      </div>
                      <div
                        className={`details-form__form-input_description ${
                          this.state.errors.post_code ? 'error' : ''
                        }`}
                      >
                        Please enter the post code in the correct format,
                        including a space.
                      </div>
                      <div
                        className={`details-form__form-input  ${
                          this.state.errors.phone_number ? 'error' : ''
                        }`}
                      >
                        <input
                          type="text"
                          value={this.props.formData.phone_number}
                          onChange={this.props.mobileOnChangeHandler}
                          placeholder="TEL OR MOBILE NUMBER"
                        />
                      </div>
                      {this.state.errors.phone_number ? (
                        <div
                          className={`details-form__form-input_description error`}
                        >
                          Tel or mobile number is required.
                        </div>
                      ) : null}
                      <div
                        className={`details-form__form-input  ${
                          this.state.errors.email ? 'error' : ''
                        }`}
                      >
                        <input
                          type="email"
                          value={this.props.formData.email}
                          onChange={this.props.emailOnChangeHandler}
                          placeholder="EMAIL ADDRESS"
                        />
                      </div>
                      {this.state.errors.email ? (
                        <div
                          className={`details-form__form-input_description error`}
                        >
                          Email address is required.
                        </div>
                      ) : null}
                      <div
                        className={`details-form__form-input  ${
                          this.state.errors.coupon ? 'error' : ''
                        }`}
                      >
                        <input
                          type="text"
                          value={this.props.formData.coupon}
                          onChange={this.props.couponOnChangeHandler}
                          placeholder="ADD PROMOTIONAL CODE (IF AVAILABLE)"
                        />
                      </div>
                    </div>
                    <div className="details-form__btn">
                      <button
                        type={'button'}
                        className="global-btn"
                        onClick={() => {
                          let errors = this.validate()
                          let errorStatus = this.validateCheckError(errors)
                          this.setState({
                            errors: { ...errors },
                          })
                          if (!errorStatus) {
                            ReactPixel.track('ButtonClick')
                            this.props.saveOrder()
                          }
                        }}
                      >
                        SUBMIT
                      </button>
                    </div>
                  </form>
                </div>
                <div
                  className="panel-title mobile"
                  style={{
                    backgroundColor:
                      this.props.affiliate?.frontend_color ?? '#c7b299',
                  }}
                  onClick={() => {
                    this.props.setSeeMyDoor(true)
                  }}
                >
                  VIEW ON MY HOUSE
                </div>
                <div className="share-my-door-btn">
                  <a>
                    SHARE MY DOOR ON SOCIAL
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="11.159"
                      height="9.809"
                      viewBox="0 0 11.159 9.809"
                    >
                      <line
                        id="Линия_25"
                        data-name="Линия 25"
                        x2="9.952"
                        transform="translate(0.5 4.905)"
                        fill="none"
                        stroke="#000"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        strokeWidth="1"
                      />
                      <line
                        id="Линия_26"
                        data-name="Линия 26"
                        x2="4.192"
                        y2="4.198"
                        transform="translate(6.26 0.707)"
                        fill="none"
                        stroke="#000"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        strokeWidth="1"
                      />
                      <line
                        id="Линия_27"
                        data-name="Линия 27"
                        y1="4.198"
                        x2="4.192"
                        transform="translate(6.26 4.904)"
                        fill="none"
                        stroke="#000"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        strokeWidth="1"
                      />
                    </svg>
                  </a>
                </div>
                <div className="details-form__inner-text">
                  {!!this.props.model &&
                  this.props.model.findIndex(
                    (item) => item.category_part_slug === 'wreath'
                  ) !== -1 ? (
                    <p>
                      Magic wreath holder must be confirmed when order is placed
                      with approved dealer
                    </p>
                  ) : null}

                  {Object.keys(this.props.affiliate).length > 0 ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.props.affiliate.gdpr_content,
                      }}
                    />
                  ) : (
                    <>
                      <p className="icon">
                        We manufacture Apeer doors and we sell only to trade
                        companies who are on our approved retailer list. Please
                        tick this box to confirm you are happy for one of our
                        approved retailers to contact you with a price for this
                        door design.
                      </p>
                      <p>
                        We do not pass your details to any other third parties.
                        We may email you in the future with further information
                        and updates to our Apeer Doors range and with
                        information on a similar product range called Lumi
                        Windows and Doors which may also be of interest to you
                        and you can opt out of these emails at any stage. Lumi
                        Windows and Doors are manufactured by Apeer and are sold
                        through an approved retailer network.
                      </p>
                      <p>
                        Every effort has been made to reproduce the colour of
                        our Apeer doors and decorative glass, however
                        limitations in capturing and displaying images may lead
                        to some deviation. Images are for illustrative purposes
                        only. Door installed may differ from the image shown. We
                        recommend visiting an Apeer approved showroom to confirm
                        before the order is placed. Please note your enquiry
                        will be passed to your nearest approved Apeer door
                        supplier and colour samples are available in their
                        showroom to confirm final colour details before sign
                        off.
                      </p>
                    </>
                  )}
                </div>
                <div className="details-form__inner-text mobile">
                  {!!this.props.model &&
                  this.props.model.findIndex(
                    (item) => item.category_part_slug === 'wreath'
                  ) !== -1 ? (
                    <p>
                      Magic wreath holder must be confirmed when order is placed
                      with approved dealer
                    </p>
                  ) : null}
                  {Object.keys(this.props.affiliate).length === 0 ? (
                    <>
                      {' '}
                      <p>
                        Every effort has been made to reproduce the colour of
                        our Apeer doors and decorative glass, however
                        limitations in capturing and displaying images may lead
                        to some deviation. Images are for illustrative purposes
                        only.
                      </p>
                      <p>
                        Please note your enquiry will be passed to your nearest
                        approved Apeer door supplier and colour samples are
                        available in their showroom to confirm final colour
                        details before sign off.
                      </p>
                    </>
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.props.affiliate.gdpr_content,
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </SimpleBar>
        </BuildItemsConstructor>

        <FormFooterConstructorThird
          classAdd="hide"
          saveOrder={this.props.saveOrder}
          link="/frame-style-second"
          setSeeMyDoor={this.props.setSeeMyDoor}
          greenButtonHandler={this.props.greenButtonHandler}
          setShowImageMobile={this.props.setShowImageMobile}
        />
      </>
    )
  }
}

function mapStateToProps(state) {
  return {
    affiliate: state.affiliate.data,
  }
}

export default connect(mapStateToProps)(MyDreamDoor)
