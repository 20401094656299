import React from 'react'
import Main from '../layouts/Main'
import MainBoxAsite from '../GeneralComponents/MainBoxAsite'
import BackHomeSecond from '../BuildDoor/BackHomeSecond'
import MainTitleBlackText from '../GeneralComponents/MainTitleBlackText'
import FavouriteStyleBannerSecond from './FavouriteStyleBannerSecond'
import FavouriteStyleFooter from './FavouriteStyleFooter'
import { connect } from 'react-redux'
import { retrieveOrders } from '../../redux/actions/orders'
import Loader from '../Loader/Loader'
import { NavLink } from 'react-router-dom'
import { getBrands } from './../../redux/actions/brands'
import SimpleBar from 'simplebar-react'
import { allSettings } from '../../redux/actions/settings'
import axios from 'axios'
import { withCookies, Cookies } from 'react-cookie'
import CookieAccept from '../CookieAccept'
import BackHomeThird from '../BuildDoor/BackHomeThird'

class ChooseYourFavouriteStyle extends React.Component {
  state = {
    list: [],
    status: true,
    brands: [],
    affiliate: {},
    settings: {},
  }

  componentDidMount() {
    this.props.getBrands().then((response) => {
      if (response.data.success) {
        this.setState({
          brands: [...response.data.data],
          status: false,
        })
      }
    })
    this.props.allSettings().then((response) => {
      this.setState({
        settings: { ...response },
      })
    })
  }

  render() {
    return (
      <Main header={true} affiliate={{}}>
        <MainBoxAsite>
          <BackHomeThird
            title="Back to main site"
            link={'https://www.apeer.co.uk/'}
          />
        </MainBoxAsite>
        <div className="main-box-content">
          <MainTitleBlackText
            classAdd="main-title-stage favourite-style"
            children="Choose your favourite style"
          />

          <div className="main-box-content__inner">
            <SimpleBar className="scrollable-area" forceVisible="y">
              <img
                src={
                  window.innerWidth > 600
                    ? this.state.settings['site.top_banner']
                    : this.state.settings['site.top_banner_mobile']
                }
                alt=""
                className="main-box-content__inner-banner"
              />
              <div className="main-box-content__inner-container">
                <div className="container container-height">
                  <h2 className="buid-door__header">
                    Optional A++ upgrade on all doors!
                  </h2>
                  <div className="build-door__box favourite-style">
                    {this.state.brands.map((brand, index) => {
                      return (
                        <div
                          className="build-door__box-item"
                          key={`brand_${index}`}
                        >
                          <NavLink to={`/admin-constructor/${brand.slug}`}>
                            <div className="build-door__box-item-title">
                              <div className="build-door__box-item-title-header">
                                {brand.name}
                              </div>

                              <div className="build-door__box-item-title-description">
                                {brand.description}
                              </div>
                            </div>
                            <div className="build-door__box-item-img">
                              <div className="build-door__box-item-img-overlay">
                                <img src={brand.overlay} alt="" />
                              </div>
                              <img src={brand.image_url} alt="" />
                            </div>
                            <div className="build-door__box-item-link">
                              Build Your A++ Door
                            </div>
                          </NavLink>
                          <div
                            className="build-btn popup-btn"
                            onClick={() => {
                              this.props.history.push(
                                `/admin-constructor/${brand.slug}`
                              )
                            }}
                          >
                            <div className="build-btn-img">
                              <img
                                src={
                                  require('../../assets/images/arrow-black.svg')
                                    .default
                                }
                                alt=""
                              />
                            </div>
                            <div className="build-btn-text">LET’S BUILD</div>
                          </div>
                        </div>
                      )
                    })}
                    {/* <div className="build-door__box-item">
											<NavLink to={`/most-popular-doors`}>
												<div className="build-door__box-item-title">{ }</div>
												<div className="build-door__box-item-img">
													<img
														src={
															require("./../../assets/images/AP_Access.jpg")
																.default
														}
														alt=""
													/>
												</div>
												<div className="build-door__box-item-link">
												Build Your Dream Door
												</div>
											</NavLink>
											<div className="build-btn popup-btn">
												<div className="build-btn-img">
													<img
														src={
															require("../../assets/images/arrow-black.svg")
																.default
														}
														alt=""
													/>
												</div>
												<div className="build-btn-text">LET’S BUILD</div>
											</div>
										</div> */}
                  </div>
                </div>
                <a
                  href={this.state.settings['site.file_of_banner']}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={
                      window.innerWidth > 600
                        ? this.state.settings['site.bottom_banner']
                        : this.state.settings['site.bottom_banner_mobile']
                    }
                    alt=""
                    className="main-box-content__inner-banner"
                  />
                </a>
                <FavouriteStyleBannerSecond />
                <FavouriteStyleFooter />
              </div>
            </SimpleBar>
          </div>
        </div>
        <Loader status={this.state.status} />
        <CookieAccept />
      </Main>
    )
  }
}

function mapStateToProps(state) {
  return {}
}
function mapDispatchToProps(dispatch) {
  return {
    retrieveOrders: () => {
      return dispatch(retrieveOrders())
    },
    getBrands: () => {
      return dispatch(getBrands())
    },
    allSettings: () => {
      return dispatch(allSettings())
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCookies(ChooseYourFavouriteStyle))
