import React from "react";

class MainBoxContentInnerImg extends React.Component {
  render() {
    return (
      <div
        className="main-box-content__inner-img"
        style={{
          backgroundImage: `url(${
            require("../../../assets/images/Silka_LR.jpg")
          })`,
        }}
      ></div>
    );
  }
}

export default MainBoxContentInnerImg;
