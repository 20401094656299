import React from "react";
import SimpleBar from "simplebar-react";
import FavouriteStyleFooter from "../ChooseYourFavouriteStyle/FavouriteStyleFooter";
import EnergyEfficiencyListPanel from "./EnergyEfficiencyListPanel";
class EnergyEfficiencyContent extends React.Component {
  render() {
    return (
      <div className="energyEfficiency__wrapper">
        <SimpleBar className="scrollable-area" forceVisible="y">
          <div className="main-box-content__inner-container ">
            <EnergyEfficiencyListPanel />
            <div className="efficiency-info__wrapper">
              <div className="efficiency-info__first">
                <div className="efficiency-info__first-item">
                  <div className="efficiency-info__first-item-con">
                    <div className="efficiency-info__first-item-con__inner">
                      <div className="efficiency-info-title">
                        Quiet storms and cosy toes.
                      </div>
                      <div className="efficiency-info__first-item-img">
                        <img
                          src={
                            require("../../assets/images/energy-efficiency/efficiency-info-1.jpg")
                           
                          }
                          alt=""
                        />
                      </div>
                      <div className="efficiency-info-text-inner">
                        <div className="efficiency-info-text">
                          Close our doors and the world outside disappears.
                          Triple glazing keeps the noise down, while virtually
                          airtight seals keep the heat in. No thrum from lashing
                          rain or whistling, swirling wind. Door closed. Coat
                          off. Now you’re home.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="efficiency-info__first-item">
                  <div className="efficiency-info__first-item-img">
                    <img
                      src={
                        require("../../assets/images/energy-efficiency/efficiency-info-2.jpg")
                        
                      }
                      alt=""
                    />
                  </div>
                  <div className="efficiency-info__first-item-img">
                    <img
                      src={
                        require("../../assets/images/energy-efficiency/efficiency-info-3.jpg")
                       
                      }
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="efficiency-info__second">
                <div className="efficiency-info__second__inner">
                  <div className="efficiency-info-text">
                    <p>
                      Our doors are at their best when the weather is at its
                      worst.
                    </p>
                    <div className="efficiency-info-text-mb">
                      <p>
                        For all you know, it could be blowing a gale outside,
                        lashing rain or hailstones. Maybe all three at once. But
                        you can’t hear the wind’s howl or feel the temperature
                        drop. That’s because you have an Apeer front door.
                        Triple glazed, double rebated and twice the thickness of
                        most others, it effortlessly keeps mother nature’s
                        wildest tantrums at bay.
                      </p>
                    </div>

                    <p>Good for the planet. Even better for your wallet.</p>
                    <p>
                      Sealing heat in while keeping cold out, they are A-Rated
                      by independent testers and far exceed the industry
                      benchmark. By keeping more heat in your home, you’ll keep
                      more cash in your wallet.
                    </p>
                  </div>
                </div>
                <div className="efficiency-info__second-img-box">
                  <div className="efficiency-info__second-img">
                    <img
                      src={
                        require("../../assets/images/energy-efficiency/efficiency-info-4.jpg")
                        
                      }
                      alt=""
                    />
                  </div>
                  <div className="efficiency-info__second-img">
                    <img
                      src={
                        require("../../assets/images/energy-efficiency/efficiency-info-5.jpg")
                        
                      }
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="efficiency-info__third">
                <div className="efficiency-info-text">
                  <p>Virtually Airtight</p>
                  <div className="efficiency-info-text-mb">
                    <p>
                      Our double sealed rebate is virtually airtight by creating
                      an internal secondary seal.
                    </p>
                  </div>
                  <p>Extra Insulation</p>
                  <p>
                    Along with our 70mm super-strong, highly insulating
                    polyurethane core, glass elements are triple glazed for
                    extra insulation. We can even gas-fill them for improved
                    thermal performance.
                  </p>
                </div>
              </div>
            </div>
            <FavouriteStyleFooter>
              <div className="favourite-style-footer-backbtn">
                <a href="/"> {"< BACK"} </a>
              </div>
            </FavouriteStyleFooter>
          </div>
        </SimpleBar>
      </div>
    );
  }
}

export default EnergyEfficiencyContent;
