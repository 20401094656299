import React from 'react'
import { withRouter } from 'react-router-dom'

class SeeOnMyHouseBottpanelControl extends React.Component {
  render() {
    return (
      <div
        className={
          'build__bottpanel bg-black ' +
          (!this.props.myDoorEditor ? 'functional-btn-hidden' : '')
        }
      >
        <div className="functional-btn">
          <div className="functional-btn-con functional-btn__change">
            <div
              className="functional-btn-button change"
              onClick={() => {
                this.props.setMyDoorEditor(false)
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23.068"
                height="23.068"
                viewBox="0 0 23.068 23.068"
              >
                <g
                  id="Сгруппировать_18"
                  data-name="Сгруппировать 18"
                  transform="translate(-3979.652 -7346.773)"
                >
                  <rect
                    id="Прямоугольник_19"
                    data-name="Прямоугольник 19"
                    width="22.567"
                    height="22.567"
                    transform="translate(4002.47 7347.023) rotate(90.002)"
                    fill="none"
                    stroke="#fff"
                  />
                  <path
                    id="Контур_19"
                    data-name="Контур 19"
                    d="M3991.186,7362.358a4.052,4.052,0,1,1,4.051-4.051A4.056,4.056,0,0,1,3991.186,7362.358Zm0-7.635a3.583,3.583,0,1,0,3.583,3.584A3.588,3.588,0,0,0,3991.186,7354.723Z"
                    fill="#fff"
                  />
                </g>
              </svg>
            </div>
            <div className="functional-btn-label">CHANGE IMAGE</div>
          </div>
          <div className="functional-btn-con functional-btn__zoom">
            <div className="functional-btn-box">
              <div
                className="functional-btn-button min"
                onClick={() => {
                  this.props.minusZoom()
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23.068"
                  height="23.068"
                  viewBox="0 0 23.068 23.068"
                >
                  <g
                    id="Сгруппировать_15"
                    data-name="Сгруппировать 15"
                    transform="translate(-4074.947 -7346.773)"
                  >
                    <rect
                      id="Прямоугольник_18"
                      data-name="Прямоугольник 18"
                      width="22.567"
                      height="22.567"
                      transform="translate(4097.765 7347.023) rotate(90.002)"
                      fill="none"
                      stroke="#fff"
                    />
                    <path
                      id="Контур_18"
                      data-name="Контур 18"
                      d="M4089.945,7358.478h-6.939a.168.168,0,1,1,0-.337h6.94a.168.168,0,0,1,0,.337Z"
                      fill="#fff"
                    />
                  </g>
                </svg>
              </div>
              <div
                className="functional-btn-button max"
                onClick={() => {
                  this.props.plusZoom()
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23.068"
                  height="23.068"
                  viewBox="0 0 23.068 23.068"
                >
                  <g
                    id="Сгруппировать_14"
                    data-name="Сгруппировать 14"
                    transform="translate(-4109.469 -7346.772)"
                  >
                    <rect
                      id="Прямоугольник_17"
                      data-name="Прямоугольник 17"
                      width="22.567"
                      height="22.567"
                      transform="translate(4132.287 7347.023) rotate(90.002)"
                      fill="none"
                      stroke="#fff"
                      strokeMiterlimit="10"
                      strokeWidth="0.5"
                    />
                    <g id="Сгруппировать_13" data-name="Сгруппировать 13">
                      <path
                        id="Контур_16"
                        data-name="Контур 16"
                        d="M4124.467,7358.478h-6.94a.168.168,0,0,1,0-.337h6.94a.168.168,0,0,1,0,.337Z"
                        fill="#fff"
                      />
                      <path
                        id="Контур_17"
                        data-name="Контур 17"
                        d="M4120.828,7361.778v-6.939a.168.168,0,0,1,.336,0v6.939a.168.168,0,1,1-.337,0Z"
                        fill="#fff"
                      />
                    </g>
                  </g>
                </svg>
              </div>
            </div>

            <div className="functional-btn-label">ZOOM</div>
          </div>
          <div
            className="functional-btn-con functional-btn__reset"
            onClick={() => {
              this.props.history.push('/')
            }}
          >
            <div className="functional-btn-button reset">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23.068"
                height="23.068"
                viewBox="0 0 23.068 23.068"
              >
                <g
                  id="Сгруппировать_21"
                  data-name="Сгруппировать 21"
                  transform="translate(-4197.339 -7346.773)"
                >
                  <rect
                    id="Прямоугольник_20"
                    data-name="Прямоугольник 20"
                    width="22.567"
                    height="22.567"
                    transform="translate(4220.157 7347.023) rotate(90.002)"
                    fill="none"
                    stroke="#fff"
                  />
                  <g id="Сгруппировать_20" data-name="Сгруппировать 20">
                    <path
                      id="Контур_20"
                      data-name="Контур 20"
                      d="M4204.261,7358.083h9.225a.223.223,0,0,1,0,.447h-9.225a.223.223,0,1,1,0-.447Z"
                      fill="#fff"
                    />
                    <path
                      id="Контур_21"
                      data-name="Контур 21"
                      d="M4204.261,7358.083a.226.226,0,0,1,.158.065l3.3,3.3a.224.224,0,0,1-.316.317l-3.3-3.3a.223.223,0,0,1,.158-.382Z"
                      fill="#fff"
                    />
                    <path
                      id="Контур_22"
                      data-name="Контур 22"
                      d="M4207.559,7354.784a.219.219,0,0,1,.158.066.221.221,0,0,1,0,.316l-3.3,3.3a.224.224,0,0,1-.317-.317l3.3-3.3A.223.223,0,0,1,4207.559,7354.784Z"
                      fill="#fff"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <div className="functional-btn-label">RESET DOOR</div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(SeeOnMyHouseBottpanelControl)
