import React, { useEffect, useState } from 'react'
import { withCookies, Cookies, useCookies } from 'react-cookie'

function CookieAccept() {
  const [cookies, setCookie, removeCookie] = useCookies()
  const [visible, setVisible] = useState(false)
  useEffect(() => {
    if (cookies.cookie_notice_accepted === undefined) {
      setVisible(true)
    }
  }, [])

  const openLink = () => {
    window.open(
      'https://www.apeer.co.uk/privacy-policy/',
      '_blank' // <- This is what makes it open in a new window.
    )
  }

  return (
    <>
      {visible ? (
        <div className="cookie-overlay">
          <div
            id="cookie-notice"
            role="dialog"
            className="cookie-revoke-hidden cn-position-bottom cn-effect-fade cookie-notice-visible"
            aria-label="Cookie Notice"
            style={{ backgroundColor: '#32323aff' }}
          >
            <div className="cookie-notice-container" style={{ color: '#fff' }}>
              <span id="cn-notice-text" className="cn-text-container">
                We use cookies to ensure that we give you the best experience on
                our website. If you continue to use this site we will assume
                that you are happy with it.
              </span>
              <span id="cn-notice-buttons" className="cn-buttons-container">
                <a
                  href="#"
                  id="cn-accept-cookie"
                  data-cookie-set="accept"
                  class="cn-set-cookie cn-button"
                  aria-label="Ok"
                  style={{ backgroundColor: '#0a0000' }}
                  onClick={(e) => {
                    {
                      e.preventDefault()
                      setVisible(false)
                      setCookie('cookie_notice_accepted', true)
                    }
                  }}
                >
                  Ok
                </a>
                <a
                  href="#"
                  id="cn-refuse-cookie"
                  data-cookie-set="refuse"
                  className="cn-set-cookie cn-button"
                  aria-label="No"
                  style={{ backgroundColor: '#0a0000' }}
                  onClick={(e) => {
                    {
                      e.preventDefault()

                      setCookie('cookie_notice_accepted', false)
                      setVisible(false)
                    }
                  }}
                >
                  No
                </a>
                <a
                  href="https://www.apeer.co.uk/privacy-policy/"
                  target="_blank"
                  id="cn-more-info"
                  className="cn-more-info cn-button"
                  aria-label="Privacy policy"
                  style={{ backgroundColor: '#0a0000' }}
                >
                  Privacy policy
                </a>
              </span>
              {/* <span
                id="cn-close-notice"
                data-cookie-set="accept"
                className="cn-close-icon"
                title="No"
                onClick={() => {
                  {
                    openLink()
                    setCookie('cookie_notice_accepted', false)
                  }
                }}
              ></span> */}
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default CookieAccept
