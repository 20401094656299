import 'react-notifications/lib/notifications.css'
import './assets/css/normalize.min.css'
import './assets/css/fix.css'
import 'simplebar-react/dist/simplebar.min.css'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import React, { useEffect } from 'react'
import MyVirtualShowroom from './components/MyVirtualShowroom/MyVirtualShowroom'

import ChooseYourFavouriteStyle from './components/ChooseYourFavouriteStyle/ChooseYourFavouriteStyle'

import EnergyEfficiency from './components/EnergyEfficiency/EnergyEfficiency'
import SecurityFeatures from './components/EnergyEfficiency/SecurityFeatures'
import ColourChoices from './components/EnergyEfficiency/ColourChoices'
import GlassDesigns from './components/EnergyEfficiency/GlassDesigns'

import MostPopularDoorsSecond from './components/MostPopularDoorsSecond/MostPopularDoorsSecond'
import SeeOnMyHouse from './components/SeeOnMyHouse/SeeOnMyHouse'
import SeeOnMyHouseSecond from './components/SeeOnMyHouse/SeeOnMyHouseSecond'

import { NotificationContainer } from 'react-notifications'

import AbstractConstructor from './components/AbstractConstructor'
import ProductCheckout from './components/ProductCheckout/ProductCheckout'
import Login from './components/Login/Login'
import './assets/css/style.min.css'
import './assets/css/custom.css'
import IFrameContainer from './components/IFrameContainer/IFrameContainer'
import ThankYou from './components/ThankYou/ThankYou'
import ReactPixel from 'react-facebook-pixel'

function App() {
  useEffect(() => {
    ReactPixel.init('281278712356503')
    ReactPixel.pageView() // Відправте подію відвідування сайту
  }, [])

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={ChooseYourFavouriteStyle} />
        <Route path="/my-virtual-showroom" component={MyVirtualShowroom} />

        <Route
          path={'/consumer-flow-constructor/:id?'}
          component={AbstractConstructor}
        />

        <Route
          path="/choose-your-favourite-style"
          component={ChooseYourFavouriteStyle}
        />
        <Route
          path={'/admin-constructor/:brand/(partner-id)?/:iframePartnerPath?'}
          component={AbstractConstructor}
        />
        <Route path="/energy-efficiency" component={EnergyEfficiency} />
        <Route path={'/security-features'} component={SecurityFeatures} />
        <Route path={'/colour-choices'} component={ColourChoices} />
        <Route path={'/glass-designs'} component={GlassDesigns} />

        <Route path="/most-popular-doors" component={MostPopularDoorsSecond} />

        <Route path="/see-on-my-house" component={SeeOnMyHouse} />
        <Route path="/see-on-my-house-second" component={SeeOnMyHouseSecond} />
        <Route path={'/product-checkout/:id'} component={ProductCheckout} />
        <Route path={'/login'} component={Login} />
        <Route
          path={'/iframe-container/:iframePartnerPath'}
          component={IFrameContainer}
        />
        <Route
          path={'/thank-you/:order/:iframePartnerPath?'}
          component={ThankYou}
        />
      </Switch>
      <NotificationContainer />
    </BrowserRouter>
  )
}

export default App
