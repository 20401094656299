import { combineReducers } from 'redux'
import affiliateReducer from './affiliateReducer'

import settingsReducer from './settingsReducer'
import userReducer from './userReducer'

export default combineReducers({
  settings: settingsReducer,
  user: userReducer,
  affiliate: affiliateReducer,
})
