import React from 'react'
import {  withRouter } from 'react-router-dom'

class HeaderInnerLogo extends React.Component {
  render() {
    let imgStyle = {
      height: 40,
      width: 'auto',
    }
    return (
      <div className="header__inner-logo">
        {Object.keys(this.props.affiliate).length > 0 ? (
          <a
            href={`/iframe-container/${this.props.match.params.iframePartnerPath}`}
          >
            {' '}
            <img
              src={this.props.affiliate.logo}
              style={{ ...imgStyle }}
              alt={''}
            />
          </a>
        ) : (
          <a href="/">
            {' '}
            <img
              style={{ ...imgStyle }}
              src={require('../../../assets/images/logo.svg').default}
              alt={''}
            />
          </a>
        )}
      </div>
    )
  }
}

export default withRouter(HeaderInnerLogo)
