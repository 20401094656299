import React from 'react'

class MobileTitleConstructorSecond extends React.Component {
  render() {
    return (
      <div
        className={
          'build__title-mobile build__title-mobile--active' +
          ' ' +
          (this.props.classAdd !== undefined ? this.props.classAdd : '')
        }
      >
        {this.props.title}
      </div>
    )
  }
}

export default MobileTitleConstructorSecond
