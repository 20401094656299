import React from 'react'
import { connect } from 'react-redux'

class SeeOnMyHouseBottpanel extends React.Component {
  render() {
    return (
      <div
        className={
          'build__bottpanel bg-gray ' +
          (!this.props.myDoorEditor ? 'functional-btn-hidden' : '')
        }
      >
        <div className="build__bottpanel-btnfs air-2">
          <a
            className="air-link"
            href="#"
            onClick={(event) => {
              event.preventDefault()
              this.props.hideSeeMyDoor()
            }}
            style={{
              backgroundColor:
                this.props.affiliate?.frontend_color ?? '#c7b299',
            }}
          >
            CLOSE PREVIEW
          </a>
        </div>
      </div>
    )
  }
}
function mapStateToProps(state) {
  return {
    affiliate: state.affiliate.data,
  }
}

export default connect(mapStateToProps)(SeeOnMyHouseBottpanel)
