import React from "react";

class SeeOnMyHouseSecondPreview extends React.Component {
  render() {
    return (
      <div className="summary__img summary-preview gallery">
        <div className="summary__img-box">
          <img src={require("../../assets/images/house.jpg")} alt="" />
        </div>
      </div>
    );
  }
}

export default SeeOnMyHouseSecondPreview;
