import React from 'react'
import { SET_AFFILIATE } from '../actions/actionTypes'
import axios from 'axios'

export function showAffiliate(name) {
  return async (dispatch, getState) => {
    try {
      let mainUrl = getState().settings.mainUrl
      let response = await axios.get(mainUrl + `/api/affiliates/${name}`)
      dispatch({
        type: SET_AFFILIATE,
        data: response.data,
      })
      return response
    } catch (e) {
      dispatch({
        type: SET_AFFILIATE,
        data: {},
      })
      return {}
    }
  }
}
