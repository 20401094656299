import React from 'react'
import TitleConstructor from '../../ElementsConstructor/TitleConstructor'
import MobileTitleConstructorSecond from '../../ElementsConstructor/MobileTitleConstructorSecond'
import BuildItemsConstructor from '../../ElementsConstructor/BuildItemsConstructor'
import BuildItemsInnerBoxConstructorMain from '../../ElementsConstructor/BuildItemsInnerBoxConstructorMain'
import FrameStyleItem from '../../ElementsConstructor/FrameStyleItem'
import FormFooterConstructorFrameStyle from './FormFooterConstructorFrameStyle'

class FrameStyleSecond extends React.Component {
  render() {

    let parts = this.props.parts.filter(part => part.brand === this.props.brand)
    return (
      <>
        <TitleConstructor title="Frame style" classAdd="white" />
        <MobileTitleConstructorSecond title="Scroll to find the frame style that suits your home" />

        <BuildItemsConstructor>
          <BuildItemsInnerBoxConstructorMain
            classAdd="duplicate"
            classAddBox="mobile frame-inner"
          >
            {parts.map((part, index) => {
              return (
                <FrameStyleItem
                  model={this.props.model}
                  checkLayerInModel={this.props.checkLayerInModel}
                  part={part}
                  key={`part_${index}`}
                  chooseComponent={this.props.chooseComponent}
                />
              )
            })}
          </BuildItemsInnerBoxConstructorMain>
        </BuildItemsConstructor>
        <FormFooterConstructorFrameStyle
          link="/frame-style"
          greenButtonHandler={this.props.greenButtonHandler}
          backButtonHandler={this.props.backButtonHandler}
          setSeeMyDoor={this.props.setSeeMyDoor}
          setShowImageMobile={this.props.setShowImageMobile}
          showImageMobile={this.props.showImageMobile}
        />
      </>
    )
  }
}

export default FrameStyleSecond
