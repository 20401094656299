import React from 'react'
import SeeOnMyHouseBottpanel from './SeeOnMyHouseBottpanel'
import SimpleBar from 'simplebar-react'
import { connect } from 'react-redux'
class SeeOnMyHouseSecondPanel extends React.Component {
  render() {
    return (
      <div className="summary__con-inner gallery scrollable-area">
        <div className="summary__con ">
          <div
            className="summary__title white "
            style={{
              backgroundColor:
                this.props.affiliate?.frontend_color ?? '#c7b299',
            }}
          >
            My Dream Door
          </div>

          <div className="simple-bar__wrapper">
            <SimpleBar className="scrollable-area" forceVisible="y">
              <div className="simple-bar__inner">
                {this.props.children}
                <div className="build__title-mobile build__title-mobile--active">
                  Use the hand & arrow tools to reposition the door on your
                  image. When you are happy with the positioning you can
                  download the image of your new front door
                </div>
              </div>
            </SimpleBar>
          </div>
          <div className="summary-btn-box">
            <a
              className="global-btn global-btn-arrow"
              onClick={(e) => {
                e.preventDefault()
                this.props.hideSeeMyDoor()
              }}
            >
              {' '}
              REQUEST A PRICE
            </a>
            <a
              className="global-btn global-btn-arrow blue"
              onClick={(e) => {
                e.preventDefault()
                this.props.downloadHouse()
              }}
            >
              DOWNLOAD PHOTO
            </a>
          </div>
          <SeeOnMyHouseBottpanel
            myDoorEditor={this.props.myDoorEditor}
            setSeeMyDoor={this.props.setSeeMyDoor}
            setMyDoorEditor={this.props.setMyDoorEditor}
            hideSeeMyDoor={this.props.hideSeeMyDoor}
          />
        </div>
      </div>
    )
  }
}
function mapStateToProps(state) {
  return {
    affiliate: state.affiliate.data,
  }
}

export default connect(mapStateToProps)(SeeOnMyHouseSecondPanel)
