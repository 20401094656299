/* global $ */
import React, { Component } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import SimpleBar from "simplebar-react";

class BuildItemsInnerBoxConstructor extends Component {
  render() {
    return (
      <div className="build__items-inner-box ">
        <SimpleBar className="scrollable-area" forceVisible="y">
          <div className="bild-door-box">
            <div
              className={
                "build__items-inner owl-desktop" +
                " " +
                (this.props.classAdd !== undefined ? this.props.classAdd : "")
              }
            >
              {this.props.children}
            </div>
            <OwlCarousel
              className={
                "owl-theme build__items-inner owl-mobile" +
                " " +
                (this.props.classAdd !== undefined ? this.props.classAdd : "")
              }
              loop
              nav={true}
              margin={30}
              center
              items={2}
              dots={false}
              navText={[
                `<img src="${
                  require("../../assets/images/prev-arrow-slider-icon.svg").default
                  
                }">`,
                `<img src="${
                  require("../../assets/images/next-arrow-slider-icon.svg").default
                 
                }">`,
              ]}
            >
              {this.props.children}
            </OwlCarousel>
          </div>
        </SimpleBar>
      </div>
    );
  }
}

export default BuildItemsInnerBoxConstructor;
