import { SET_AFFILIATE } from '../actions/actionTypes'

const initializeState = {
  data: {},
}

export default function affiliateReducer(state = initializeState, action) {
  switch (action.type) {
    case SET_AFFILIATE:
      return {
        ...state,
        data: {
          ...action.data,
        },
      }
    default:
      return { ...state }
  }
}
