import React from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom'

class BackHomeSecond extends React.Component {
  render() {
    return (
      <div className="back-home">
        <Link
          to={
            !this.props.match.params.iframePartnerPath
              ? '/'
              : `/iframe-container/${this.props.match.params.iframePartnerPath}`
          }
        >
          <div className="back-home-img">
            <img
              src={require('../../assets/images/arrow-white.svg').default}
              alt=""
            />
          </div>
          <div className="back-home-title br-width">{this.props.title}</div>
        </Link>
      </div>
    )
  }
}

export default withRouter(BackHomeSecond)
